// Centralizzazione dello state.

export const RESET_APP = "RESET_APP"
export const SET_LOCALE = "SET_LOCALE"
export const SET_SESSION_USER = "SET_SESSION_USER"
export const SET_PPE_KINDS = "SET_PPE_KINDS"
export const SET_PPE_ALERT_SOLUTIONS = "SET_PPE_ALERT_SOLUTIONS"
export const SET_ZONES = "SET_ZONES"
export const SET_REPORTS_DATE_FROM = "SET_REPORTS_DATE_FROM"
export const SET_REPORTS_DATE_TO = "SET_REPORTS_DATE_TO"
export const SET_REPORTS_PERIOD = "SET_REPORTS_PERIOD"
export const SET_REPORTS_ZONES = "SET_REPORTS_ZONES"
export const SET_REPORTS_DATES = "SET_REPORTS_DATES"
// A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ)
export const SET_PLAY_AUDIO = "SET_PLAY_AUDIO"
// A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ) - FINE
export const SET_MODULES = "SET_MODULES"